import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center py-4 px-6" }
const _hoisted_2 = { class: "ml-2" }
const _hoisted_3 = { class: "flex justify-center py-4 px-2" }

import { orderBy } from 'lodash';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { ColumnDefinition } from '@/components/_global/BalTable/BalTable.vue';
import DripBtn from '@/components/btns/DripBtn/DripBtn.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokenLists from '@/composables/useTokenLists';
import useTokens from '@/composables/useTokens';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const { t } = useI18n();
const { upToLargeBreakpoint } = useBreakpoints();
const { fNum2 } = useNumbers();
const { defaultTokenList } = useTokenLists();
const { priceFor, balanceFor } = useTokens();

const tokens = computed(() => {
  const tokensWithValues = Object.values(defaultTokenList.value.tokens)
    .map(token => {
      const balance = balanceFor(token.address);
      const price = priceFor(token.address);
      const value = Number(balance) * price;
      return {
        ...token,
        price,
        balance,
        value,
      };
    })
    .filter(t => t.address != '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE');

  return orderBy(tokensWithValues, ['value', 'balance'], ['desc', 'desc']);
});

/**
 * STATE
 */
const columns = ref<ColumnDefinition<any>[]>([
  {
    name: 'Token',
    id: 'token',
    accessor: 'token',
    Cell: 'tokenColumnCell',
    width: 475,
    noGrow: true,
  },
  {
    name: t('balance'),
    id: 'Balance',
    align: 'right',
    width: 150,
    accessor: ({ balance }) => `${fNum2(balance, FNumFormats.token)}`,
  },
  {
    name: t('value'),
    id: 'value',
    align: 'right',
    width: 150,
    accessor: ({ value }) => fNum2(value, FNumFormats.fiat),
  },
  {
    name: 'Drip',
    id: 'drip',
    align: 'center',
    accessor: 'drip',
    Cell: 'dripColumnCell',
    width: 150,
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "lg",
    square: _unref(upToLargeBreakpoint),
    noBorder: _unref(upToLargeBreakpoint),
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        columns: columns.value,
        data: _unref(tokens),
        isLoading: _ctx.isLoading,
        skeletonClass: "h-64",
        square: _unref(upToLargeBreakpoint)
      }, {
        tokenColumnCell: _withCtx(({ name, logoURI }) => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_BalAsset, { iconURI: logoURI }, null, 8, ["iconURI"]),
            _createElementVNode("span", _hoisted_2, _toDisplayString(name), 1)
          ])
        ]),
        dripColumnCell: _withCtx(({ address }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(DripBtn, { token: address }, null, 8, ["token"])
          ])
        ]),
        _: 1
      }, 8, ["columns", "data", "isLoading", "square"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}
}

})